import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import MainPic from '../../Images/MainPic.jpg'

import hvacImg1 from '../../Images/hvacImg1.jpg'
import hvacImg2 from '../../Images/hvacImg2.jpg'
import hvacImg3 from '../../Images/hvacImg3.jpg'
import hvacImg4 from '../../Images/hvacImg4.jpg'
import hvacImg6 from '../../Images/hvacImg6.jpg'
import hvacImg7 from '../../Images/hvacImg7.jpg'





export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>San Bernardino, CA Heating & Air Conditioning & Repair 760-973-2604 | Best Heating & Air Conditioning Services</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="San Bernardino, CA Heating & Air Conditioning & Repair 760-973-2604 | Best Heating & Air Conditioning" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="San Bernardino, CA Heating & Air Conditioning & Repair 760-973-2604 | Best Heating & Air Conditioning" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.santaanahvacairconditioning.org/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Your Trusted Source For Expert Heating & Air Conditioning Services In San Bernardino, CA. We Offer Professional Installation, Repair, And Maintenance To Keep Your Heating & Air Conditioning Secure And Functioning Smoothly. Book Your Service Online Today." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Your Trusted Source For Expert Heating & Air Conditioning Services In San Bernardino, CA. We Offer Professional Installation, Repair, And Maintenance To Keep Your Heating & Air Conditioning Secure And Functioning Smoothly. Book Your Service Online Today." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content="San Bernardino, CA Heating & Air Conditioning & Repair 760-973-2604 | Best Heating & Air Conditioning" data-react-helmet="true" />
                <meta name="keywords" content="HVAC services San Bernardino CA, Air conditioning repair San Bernardino, Heating repair San Bernardino CA, HVAC installation San Bernardino, Furnace repair San Bernardino, AC maintenance San Bernardino CA, Air duct cleaning San Bernardino, Emergency HVAC services San Bernardino, HVAC contractors San Bernardino CA, Residential HVAC San Bernardino, Commercial HVAC San Bernardino, HVAC tune-up San Bernardino, AC replacement San Bernardino, Heating system installation San Bernardino, Indoor air quality San Bernardino CA, HVAC repair near me San Bernardino, Central air conditioning San Bernardino, Ductless mini-split San Bernardino, HVAC service plan San Bernardino, Thermostat installation San Bernardino, 24/7 HVAC service San Bernardino, Energy-efficient HVAC San Bernardino, Air filter replacement San Bernardino, Heat pump repair San Bernardino, HVAC maintenance San Bernardino CA, HVAC inspection San Bernardino, Air conditioner installation San Bernardino, Heating and cooling San Bernardino, AC service San Bernardino CA, HVAC system replacement San Bernardino, Furnace installation San Bernardino CA, HVAC repair cost San Bernardino, Local HVAC company San Bernardino, Affordable HVAC San Bernardino, AC troubleshooting San Bernardino, HVAC technicians San Bernardino CA, HVAC emergency repair San Bernardino, Heater repair San Bernardino CA, Air conditioning services San Bernardino, Commercial air conditioning San Bernardino, Furnace tune-up San Bernardino, Ductwork repair San Bernardino CA, HVAC financing San Bernardino, HVAC deals San Bernardino CA, Heating and air conditioning San Bernardino, HVAC consultation San Bernardino, Central heating repair San Bernardino, HVAC contractor near me San Bernardino, Reliable HVAC San Bernardino CA, Licensed HVAC contractor San Bernardino" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Heating & Air Conditioning in San Bernardino, CA</h1>
                    <h2>Heating & Air Conditioning Near Me In San Bernardino, California</h2>
                </div>
                <div className="MainBannerRight">
                    <img src={hvacImg2} alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>San Bernardino Heating & Air Conditioning Services</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Ac-Repair" className="MainBanner2TopBoxBox">
                            <h3>AC Repair</h3>
                        </a>
                        <a href="/service/Residential-Hvac" className="MainBanner2TopBoxBox">
                            <h3>Residential HVAC</h3>
                        </a>
                        <a href="/service/Heating-And-Cooling" className="MainBanner2TopBoxBox">
                            <h3>Heating and cooling</h3>
                        </a>
                        <a href="/service/Hvac-Services" className="MainBanner2TopBoxBox">
                            <h3>HVAC Services</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBannerReviews">
                <div className="MainBannerReviewsHeader">
                    <h1>Proudly recognized as "#1 Heating & Air Conditioning Company" by industry experts.</h1>
                </div>
                <div className="MainBannerReviewsMenu">
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Yahoo%21_Finance_logo_2021.png/320px-Yahoo%21_Finance_logo_2021.png" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://cdn.worldvectorlogo.com/logos/marketwatch-logo.svg" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2024/02/Business-Insider-Logo.png" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2016/10/Associated-Press-logo.png" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2022/05/Benzinga-Logo.png" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://www.iadea.com/wp-content/uploads/2018/10/digital-journal-logo.png" alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    </div>
                </div>
            </div>
            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={hvacImg7} alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | Expert Heating & Air Conditioning Services" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <div className="MainBanner2TopBox">
                            <h2>Expert Heating & Air Conditioning Services</h2>
                            <h3>Ensure your home’s comfort and efficiency with our professional HVAC services.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Dependable Heating & Air Conditioning Inspections & Repairs</h2>
                            <h3>Rely on our expert technicians to thoroughly inspect and repair your HVAC system.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Premium Heating & Air Conditioning Maintenance</h2>
                            <h3>Prevent issues and extend the life of your HVAC system with our top-quality maintenance services.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <a href="tel:760-973-2604">CALL NOW 760-973-2604</a>
                <h1>Heating & Air Conditioning Repair Near Me</h1>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>AC Repair</h2>
                        <h3>
                            A malfunctioning air conditioning system can disrupt your daily routine and compromise the comfort of your home. Our expert AC repair services in San Bernardino, CA, are designed to address any issue quickly and effectively. Whether it’s a refrigerant leak, a faulty compressor, or an AC unit that’s not cooling properly, our skilled technicians have the experience and tools to get your air conditioning system back in top condition.
                            <br />
                            <br />
                            We understand the urgency of AC problems, which is why we offer prompt, same-day service for most repairs. Our team uses high-quality replacement parts to ensure lasting results, so you won’t have to worry about recurring issues. We also provide a thorough inspection during every repair to identify any underlying problems that could cause future headaches.
                            <br />
                            <br />
                            Safety is our top priority. A malfunctioning AC unit can be hazardous, especially if it involves electrical components or refrigerant leaks. Trust our professionals to handle these repairs safely and efficiently, restoring your peace of mind.
                            <br />
                            <br />
                            Don’t let a faulty AC system disrupt your life. Contact us today to schedule a repair, and let our experts take care of your air conditioning needs with the professionalism and attention to detail you deserve.
                        </h3>
                        <a href="/service/Ac-Repair">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>Residential HVAC</h2>
                        <h3>
                            A malfunctioning residential HVAC system can disrupt your daily comfort and compromise the efficiency of your home. Our expert residential HVAC repair services in San Bernardino, CA, are designed to address any issue quickly and effectively. Whether it’s a heating system that’s not warming your home, an air conditioner that’s not cooling, or a thermostat that’s malfunctioning, our skilled technicians have the experience and tools to get your HVAC system back in top condition.
                            <br />
                            <br />
                            We understand the importance of a comfortable home environment, which is why we offer prompt, same-day service for most HVAC repairs. Our team uses high-quality replacement parts to ensure lasting results, so you won’t have to worry about recurring issues. We also provide a thorough inspection during every repair to identify any underlying problems that could cause future discomfort.
                            <br />
                            <br />
                            Safety is our top priority. A malfunctioning HVAC system can be dangerous, especially if it involves faulty wiring, gas leaks, or malfunctioning components. Trust our professionals to handle these repairs safely and efficiently, restoring your home’s comfort and your peace of mind.
                            <br />
                            <br />
                            Don’t let a faulty HVAC system disrupt your life. Contact us today to schedule a repair, and let our experts take care of your residential HVAC needs with the professionalism and attention to detail you deserve.
                        </h3>
                        <a href="/service/Residential-Hvac">Learn More</a>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>Heating and cooling</h2>
                        <h3>
                            A malfunctioning heating and cooling system can disrupt your home’s comfort and significantly impact your energy efficiency. Our expert heating and cooling repair services in San Bernardino, CA, are designed to address any issue quickly and effectively. Whether it’s a furnace that’s not heating, an air conditioner that’s not cooling, or an HVAC system that’s not running efficiently, our skilled technicians have the experience and tools to restore your system to optimal condition.
                            <br />
                            <br />
                            We understand the importance of maintaining a comfortable home environment, which is why we offer prompt, same-day service for most heating and cooling repairs. Our team uses high-quality replacement parts to ensure long-lasting results, so you can trust that your system will perform reliably. We also conduct a thorough inspection during every repair to identify any underlying problems that could lead to future disruptions.
                            <br />
                            <br />
                            Safety and efficiency are our top priorities. A malfunctioning heating or cooling system can be hazardous, particularly if it involves gas leaks, electrical issues, or other critical components. You can trust our professionals to handle these repairs safely and efficiently, ensuring your home remains comfortable and secure.
                            <br />
                            <br />
                            Don’t let a faulty heating or cooling system disrupt your life. Contact us today to schedule a repair, and let our experts take care of your heating and cooling needs with the professionalism and attention to detail you deserve.
                            <br />
                            <br />
                            Contact us today to schedule a service appointment and experience the difference that professional Heating & Air Conditioning opener services can make.
                        </h3>
                        <a href="/service/Heating-And-Cooling">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>HVAC Services</h2>
                        <h3>
                            A malfunctioning HVAC system can disrupt your home's comfort and efficiency, impacting your daily life. Our expert HVAC services in San Bernardino, CA, are designed to address any issue quickly and effectively. Whether it's a heating system that’s not warming your home, an air conditioner that’s not cooling, or a ventilation system that’s not performing efficiently, our skilled technicians have the experience and tools to restore your HVAC system to peak condition.
                            <br />
                            <br />
                            We understand the importance of maintaining a comfortable and healthy indoor environment, which is why we offer prompt, same-day service for most HVAC repairs. Our team uses high-quality replacement parts to ensure long-lasting results, so you can trust that your system will operate reliably. We also perform a thorough inspection during every service to identify any underlying issues that could lead to future problems.
                            <br />
                            <br />
                            Safety and efficiency are our top priorities. A malfunctioning HVAC system can pose safety risks, such as gas leaks, electrical hazards, or compromised air quality. Trust our professionals to handle these repairs safely and efficiently, ensuring your home remains comfortable, safe, and energy-efficient.
                            <br />
                            <br />
                            Don’t let a faulty HVAC system disrupt your life. Contact us today to schedule a service, and let our experts take care of your HVAC needs with the professionalism and attention to detail you deserve.
                            <br />
                            <br />
                        </h3>
                        <a href="/service/Hvac-Services">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Left">
                    <img src={hvacImg4} alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | San Bernardino Heating & Air Conditioning" />
                    <div className="MainBanner4LeftBubble1"></div>
                    <div className="MainBanner4LeftBubble2"></div>
                </div>
                <div className="MainBanner4Right">
                    <h1>OUR OUR SERVICES</h1>
                    <h2>At San Bernardino, California HVAC, we offer comprehensive HVAC services including repair, inspection, and maintenance. Our certified technicians ensure your HVAC system is safe, efficient, and in top condition. We use high-quality materials and state-of-the-art equipment to deliver exceptional results, providing peace of mind and enhancing your home's safety and comfort.</h2>
                    <div className="MainBanner4RightBenefits">
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Repair">Air Conditioning Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Services">Ac Services In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Repair-Near-Me">Hvac Repair Near Me In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Repair">Ac Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Installation">Ac Installation In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Services">Hvac Services In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Service">Air Conditioning Service In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Furnace-Repair">Furnace Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heating-And-Cooling">Heating And Cooling In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Maintenance">Ac Maintenance In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Emergency-Hvac-Service">Emergency Hvac Service In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Duct-Cleaning">Duct Cleaning In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Indoor-Air-Quality">Indoor Air Quality In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Tune-up">Hvac Tune-up In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heat-Pump-Repair">Heat Pump Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Contractors">Hvac Contractors In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Installation">Air Conditioning Installation In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Heating-System-Repair">Heating System Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Central-Air-Conditioning">Central Air Conditioning In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Residential-Hvac">Residential Hvac In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Commercial-Hvac-Services">Commercial Hvac Services In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Thermostat-Installation">Thermostat Installation In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Energy-efficient-Hvac">Energy-efficient Hvac In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ventilation-Services">Ventilation Services In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Installation">Hvac Installation In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Hvac-Repair">Hvac Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Ac-Repair-Near-Me">Ac Repair Near Me In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Condenser-Repair">Condenser Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Compressor-Repair">Compressor Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Evaporatoer-Repair">Evaporatoer Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Cooling-System-Repair">Cooling System Repair In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Air-Duct-Cleaning">Air Duct Cleaning In San Bernardino, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Dryer-Vent-Cleaning">Dryer Vent Cleaning In San Bernardino, CA</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with Heating & Air Conditioning Services in San Bernardino ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Call us.</h3>
                            <h4>Contact our expert team by calling or visiting our website to book San Bernardino Heating & Air Conditioning Services.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide some information.</h3>
                            <h4>Provide your address and specific details to our Heating & Air Conditioning professionals, including any unique aspects of your home that might affect the service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>To know your situation more.</h3>
                            <h4>Share photos of your Heating & Air Conditioningto help us understand its current condition and accurately estimate the service needed.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Date and Time.</h3>
                            <h4>Discuss the urgency of your service needs and agree on a tentative arrival date for our team.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Our team will do the best.</h3>
                            <h4>Our local service provider will determine the cost based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href="tel:760-973-2604">CALL NOW 760-973-2604</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={hvacImg2} alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <h1>Trusted Mobile and Safe Service</h1>
                <div className="MainBanner6List">
                    <div className="MainBanner6TopBox">
                        <h2>Our Mission</h2>
                        <h3>Our mission is to connect you with the leading experts in Heating & Air Conditioning services in San Bernardino. By leveraging the latest technology and specialized expertise, we deliver efficient, top-quality care for your Heating & Air Conditioning, ensuring your home remains secure and functional.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Goals</h2>
                        <h3>Our professionals are part of a premier network dedicated to excellence in every aspect of Heating & Air Conditioning services. We emphasize collaboration, bringing together the best talent and resources to handle even the most complex Heating & Air Conditioning challenges with efficiency and precision.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Values</h2>
                        <h3>Our local service providers in San Bernardino prioritize safety for you, your family, pets, and neighbors while maintaining a commitment to environmental responsibility. We also uphold values of respect and kindness in every interaction, ensuring a positive experience from start to finish.</h3>
                    </div>
                </div>
            </div>
            <div className="MainBanner6">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <h2>San Bernardino Garage Services delivered exceptional Heating & Air Conditioning repair and inspection services. The technician arrived on time, was highly professional, and conducted a thorough job. They explained the entire process clearly and ensured everything was in perfect order before leaving. I highly recommend San Bernardino Garage Services for their outstanding customer care and expertise. My Heating & Air Conditioning is now operating smoothly and securely, thanks to their top-notch work!</h2>
                <h3>5 stars company 🌟 🌟 🌟 🌟 🌟</h3>
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="MainBanner7">
                <div className="MainBanner7Left">
                    <h1>The Importance of Professional Heating & Air Conditioning Services in San Bernardino</h1>
                    <h2>Keeping your Heating & Air Conditioningclean and well-maintained is essential for the safety and efficiency of your Heating & Air Conditioning or stove. In San Bernardino, regular Heating & Air Conditioninging is a critical task that ensures your Heating & Air Conditioningoperates smoothly and reduces the risk of fire hazards. Here’s why professional Heating & Air Conditioning services are vital and what you can expect from the process.</h2>
                    <h2>
                        {/* (888) 269-4246 */}
                        <br />
                        Monday – Friday
                        <br />
                        8:00 a.m. – 8:00 p.m. ET
                    </h2>
                </div>
                <div className="MainBanner7Right">
                    <img src={hvacImg1} alt="Expert Heating & Air Conditioning Services | San Bernardino Heating & Air Conditioning | " />
                    <div className="MainBanner7RightBubble1"></div>
                    <div className="MainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

