import React, { useState } from 'react'
import closeMenu from '../../Images/closeMenu.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import book from '../../Images/book.png'
import tiktok from '../../Images/tiktok.png';
import facebook from '../../Images/facebook.png';
import instagram from '../../Images/instagram.png';
import './Menu.scss';
import $ from 'jquery';

export default function Menu({ navigateTo, setShowMenu, WebSitePages }) {

    const [showMakers, setshowMakers] = useState(false);
    return (
        <div className="Menu">
            <div className="MenuBoxShadow" onClick={() => setShowMenu(false)}></div>
            <div className="MenuList">
                <div className="MenuListTop">
                    <div className="MenuMain">
                        <div className="MenuMainBox">
                            <a href='/'>Home</a>
                        </div>
                        <div className="MenuMainBox" onClick={() => {
                            setshowMakers(true)
                        }}>
                            <h1 >Services</h1>
                            <img src={next} alt="" />
                        </div>
                        <div className="MenuMainBox">
                            <a href='/contact'>Contact Us</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/book'>Book An Appointment</a>
                        </div>
                        <div className="MenuMainBox">
                            <a href='/blog'>Blog</a>
                        </div>
                    </div>
                    <div className="MenuFooter">
                        <h5>Copyright © 2024 San Bernardino Heating & Air Conditioning. All rights reserved.</h5>
                    </div>

                    {/* showCategories */}
                    {showMakers ?
                        <div className="MenuMakers">
                            <div className="MenuMakersHeader">
                                <img src={next} alt="" onClick={() => {
                                    setshowMakers(false)
                                }} />
                                <h1 onClick={() => {
                                    // navigateTo("")
                                }}>Services</h1>
                            </div>
                            <div className="MenuMakersList">
                                <div className="MenuMakersListBox">
                                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Repair">Air Conditioning Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ac-Services">Ac Services</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Repair-Near-Me">Hvac Repair Near Me</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ac-Repair">Ac Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ac-Installation">Ac Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Services">Hvac Services</a>
                                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Service">Air Conditioning Service</a>
                                    <a className="Header1BoxMenuBox" href="/service/Furnace-Repair">Furnace Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Heating-And-Cooling">Heating And Cooling</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ac-Maintenance">Ac Maintenance</a>
                                    <a className="Header1BoxMenuBox" href="/service/Emergency-Hvac-Service">Emergency Hvac Service</a>
                                    <a className="Header1BoxMenuBox" href="/service/Duct-Cleaning">Duct Cleaning</a>
                                    <a className="Header1BoxMenuBox" href="/service/Indoor-Air-Quality">Indoor Air Quality</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Tune-up">Hvac Tune-up</a>
                                    <a className="Header1BoxMenuBox" href="/service/Heat-Pump-Repair">Heat Pump Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Contractors">Hvac Contractors</a>
                                    <a className="Header1BoxMenuBox" href="/service/Air-Conditioning-Installation">Air Conditioning Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Heating-System-Repair">Heating System Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Central-Air-Conditioning">Central Air Conditioning</a>
                                    <a className="Header1BoxMenuBox" href="/service/Residential-Hvac">Residential Hvac</a>
                                    <a className="Header1BoxMenuBox" href="/service/Commercial-Hvac-Services">Commercial Hvac Services</a>
                                    <a className="Header1BoxMenuBox" href="/service/Thermostat-Installation">Thermostat Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Energy-efficient-Hvac">Energy-efficient Hvac</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ventilation-Services">Ventilation Services</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Installation">Hvac Installation</a>
                                    <a className="Header1BoxMenuBox" href="/service/Hvac-Repair">Hvac Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Ac-Repair-Near-Me">Ac Repair Near Me</a>
                                    <a className="Header1BoxMenuBox" href="/service/Condenser-Repair">Condenser Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Compressor-Repair">Compressor Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Evaporatoer-Repair">Evaporatoer Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Cooling-System-Repair">Cooling System Repair</a>
                                    <a className="Header1BoxMenuBox" href="/service/Air-Duct-Cleaning">Air Duct Cleaning</a>
                                    <a className="Header1BoxMenuBox" href="/service/Dryer-Vent-Cleaning">Dryer Vent Cleaning</a>
                                </div>

                            </div>

                        </div>
                        : null}
                </div>
                <div className="MenuListFooter">
                    <p>
                        Monday - Sunday
                        <br />
                        8:00 a.m. - 8:00 p.m. EST
                    </p>
                    <a href="tel:760-973-2604">CALL NOW 760-973-2604</a>
                    <a href="/book">BOOK AN APPOINTMENT</a>
                </div>

            </div>
        </div>
    )
}
